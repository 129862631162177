<template>
    <v-container fluid>
        <v-form
            ref="form"
            v-model="validForm"
            lazy-validation
        >
            <v-row>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <h3>Activities</h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <hr />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.status"
                        label="Active"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.quickAccessMenu"
                        label="Card Quick Access Menu?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Panel</label>
                    <v-combobox
                        v-model="actionsRequest.panelSelected"
                        :items="listConstructionPanel" 
                        :rules=[validations.required]
                        item-text="panelDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                        @change="filterStages()"
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Stage</label>
                    <v-combobox
                        v-model="actionsRequest.stageSelected"
                        :items="listConstructionStagesFiltered" 
                        :rules=[validations.required]
                        item-text="stageDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Actions'" />
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.startDate"
                        label="Schedule Date ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                    <v-combobox
                        v-if="actionsRequest.startDate == 1"
                        v-model="actionsRequest.listIDProfileStartDateSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.endDate"
                        label="Completed Date ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                    <v-combobox
                        v-if="actionsRequest.endDate == 1"
                        v-model="actionsRequest.listIDProfileEndDateSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.sendDocument"
                        label="Send Document ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                    <v-combobox
                        v-if="actionsRequest.sendDocument == 1"
                        v-model="actionsRequest.listIDProfileSendDocumentSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <div style="display: flex;">
                        <v-switch 
                            v-model="actionsRequest.checkList"
                            label="Check List ?"
                            color="var(--color__main)"
                            :value=1
                        >
                        </v-switch>                        

                        <v-tooltip left v-if="actionsRequest.checkList == 1">
                            <template v-slot:activator="{ on }">
                                <v-chip
                                    v-if="actionsRequest.checkList == 1"
                                    class="ma-2"
                                    label
                                    link
                                    outlined
                                    style="border: none; font-size: 30px !important; margin-left: 15px !important; margin-top: 15px !important;"
                                    v-on="on"
                                    @click="openDialogCheckList()"
                                >
                                    <v-icon>mdi-check-all</v-icon>
                                </v-chip>
                            </template>
                            <span>Open Check List</span>
                        </v-tooltip>
                    </div>
                    <v-combobox
                        v-if="actionsRequest.checkList == 1"
                        v-model="actionsRequest.listIDProfileCheckListSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.notes"
                        label="Notes ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                    <v-combobox
                        v-if="actionsRequest.notes == 1"
                        v-model="actionsRequest.listIDProfileNotesSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.subContractor"
                        label="Select Subcontractor ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                    <v-combobox
                        v-if="actionsRequest.subContractor == 1"
                        v-model="actionsRequest.listIDProfileSubcontractorSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.uploadPhoto"
                        label="Upload Photo ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                    <v-combobox
                        v-if="actionsRequest.uploadPhoto == 1"
                        v-model="actionsRequest.listIDProfileUploadPhotoSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.moveProject"
                        label="Move Project ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                    <v-combobox
                        v-if="actionsRequest.moveProject == 1"
                        v-model="actionsRequest.stageToMoveSelected"
                        :items="listConstructionStages" 
                        :rules=[validations.required]
                        label="Select the stage to move"
                        item-text="stageDescription"
                        item-value="id"
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                    <v-combobox
                        v-if="actionsRequest.moveProject == 1"
                        v-model="actionsRequest.listIDProfileMoveProjectSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.deadlineDate"
                        label="Deadline Date ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                    <v-combobox
                        v-if="actionsRequest.deadlineDate == 1"
                        v-model="actionsRequest.listIDProfileDeadlineDateSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <v-switch 
                        v-model="actionsRequest.inspection"
                        label="Inspection ?"
                        color="var(--color__main)"
                        :value=1
                    >
                    </v-switch>
                </v-col>
                <v-col 
                    cols="12"
                    lg="2"
                    md="12"
                    sm="12"
                >
                    <div style="display: flex;">
                        <v-switch 
                            v-model="actionsRequest.form"
                            label="Form ?"
                            color="var(--color__main)"
                            :value=1
                        >
                        </v-switch>                   

                        <v-tooltip left v-if="actionsRequest.form == 1">
                            <template v-slot:activator="{ on }">
                                <v-chip
                                    v-if="actionsRequest.form == 1"
                                    class="ma-2"
                                    label
                                    link
                                    outlined
                                    style="border: none; font-size: 30px !important; margin-left: 15px !important; margin-top: 15px !important;"
                                    v-on="on"
                                    @click="showDialogForm = true"
                                >
                                    <v-icon>mdi-help-box-multiple-outline</v-icon>
                                </v-chip>
                            </template>
                            <span>Open Form</span>
                        </v-tooltip>
                    </div>
                    <v-combobox
                        v-if="actionsRequest.form == 1"
                        v-model="actionsRequest.listIDProfileFormSelected"
                        :items="listProfile"
                        label="Responsible for this action"
                        item-text="name"
                        item-value="id"
                        multiple
                        :rules=[validations.required]
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="6"
                    md="6"
                    sm="12"
                >
                    <label>Name</label>
                    <v-text-field
                        v-model="actionsRequest.labelName" 
                        :rules=[validations.required]
                        single-line
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>

                <v-col 
                    cols="10"
                    lg="5"
                    md="5"
                    sm="10"
                >
                    <label>MDI Icon</label>
                    <v-text-field
                        v-model="actionsRequest.iconWithoutPrefix" 
                        :rules=[validations.required]
                        single-line
                        :prefix="prefix"
                        @keyup="setMdiIcon()"
                        outlined
                        dense
                    >
                    </v-text-field>
                </v-col>
                <v-col 
                    style="display: flex; align-content: center; align-items: center;"
                    cols="1"
                    lg="1"
                    md="1"
                    sm="1"
                >
                    <v-icon large>{{actionsRequest.icon}}</v-icon>
                    <v-tooltip left>
                        <template v-slot:activator="{ on }">
                            <a 
                                href="https://materialdesignicons.com/"
                                target="_blank"
                            >
                                <v-chip
                                    class="ma-2"
                                    label
                                    link
                                    outlined
                                    style="border: none; font-size: 30px !important;"
                                    v-on="on"
                                >
                                    ...
                                </v-chip>
                            </a>
                        </template>
                        <span>To Go Site - Material Designer</span>
                    </v-tooltip>
                </v-col>
            </v-row>
            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <ContentHeader :description="'Validation Rules'" />
                </v-col>
            </v-row>

            <v-row>
                <v-col 
                    cols="12"
                    lg="12"
                    md="12"
                    sm="12"
                >
                    <label>Choose the actions that should be validated at this stage</label>
                    <v-combobox
                        v-model="actionsRequest.listIDActionsDependsOnSelected"
                        :items="listActionsFiltered"
                        item-text="descriptionFull"
                        item-value="id"
                        multiple
                        clearable
                        outlined
                        dense
                    ></v-combobox>
                </v-col>
            </v-row>

            <ActionButtons
                :request="actionsRequest"
                :loading="loading"
                :showButtons="{
                    cancel: true,
                    save: true
                }"
                @cancel="cancel"
                @save="save"
            />
        </v-form>

        <v-dialog
            v-model="showDialogCheckList"
            transition="dialog-top-transition"
            persistent
            width="900"
            :fullscreen="$vuetify.breakpoint.mobile"
        >
        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Check List
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Title</label>
                            <v-text-field
                                v-model="actionsRequest.checkListTitle" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <ContentHeader :description="'Enter the checklist'" />
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in actionsRequest.listCheckList" :key="index" style="height: 60px;" >
                        <v-col 
                            cols="9"
                            lg="10"
                            md="9"
                            sm="9"
                        >
                            <v-text-field
                                v-model="item.description" 
                                :rules=[validations.required]
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="2"
                            lg="2"
                            md="2"
                            sm="2"
                            :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                        >
                            <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="btnActions"
                                        outlined
                                        fab
                                        x-small
                                        v-on="on"
                                        @click="addNewItem(item)" 
                                        color="blue" 
                                        small
                                        :key="index"
                                    >
                                        <v-icon>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add New Item</span>
                            </v-tooltip>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="btnActions"
                                        outlined
                                        fab
                                        x-small
                                        v-on="on"
                                        @click="deleteItem(index)" 
                                        color="red" 
                                        small
                                        :disabled="actionsRequest.listCheckList.length <= 1"
                                    >
                                        <v-icon>mdi-delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete Item</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 buttonCancelDialog v-btn-large"
                            style=""
                            outlined
                            @click="showDialogCheckList = false"
                        >
                            Cancel
                        </v-btn>

                        <v-btn
                            class="mx-2 v-btn-large"
                            outlined
                            @click="showDialogCheckList = false"
                        >
                            Save
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>


        <v-dialog
            v-model="showDialogForm"
            transition="dialog-top-transition"
            persistent
            width="1000"
            :fullscreen="$vuetify.breakpoint.mobile"
        >        
            <v-card>
                <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                    Form
                </v-card-title>

                <v-card-text style="margin-top: 20px;">
                    <v-row>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Title</label>
                            <v-text-field
                                v-model="actionsRequest.formTitle" 
                                single-line
                                outlined
                                dense
                            >
                            </v-text-field>
                        </v-col>
                        <v-col 
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <label>Description</label>
                            <v-textarea
                                v-model="actionsRequest.formDescription"
                                rows="2"
                                row-height="15"
                                auto-grow
                                outlined
                            >
                            </v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col
                            style="text-align: right"
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn 
                                        class="mx-2"
                                        v-on="on"
                                        @click="showDialogActionsFormAndClearFields()"
                                    >
                                        Add New Question
                                    </v-btn>
                                </template>
                                <span>Add New Question</span>
                            </v-tooltip>
                        </v-col>
                        <v-col  
                            cols="12"
                            lg="12"
                            md="12"
                            sm="12"
                        >
                            <br />
                            <v-data-table
                                :headers="headersActionsForm"
                                :options.sync="optionsActionsForm"
                                :items="actionsRequest.listActionsForm"
                                no-data-text="No questions registered"
                            >
                                <template v-slot:item.action="{item}">
                                    <ActionList 
                                        :id="item.id" 
                                        :showButtons="{
                                            edit: true,
                                            delete: true
                                        }"
                                        @customActionEdit="showDialogActionsFormAndFillFields"
                                        @confirmDelete="confirmActionsFormDelete" />
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <div style="width: 100%; text-align: center;">
                        <v-btn
                            class="mx-2 dialogButtonDismiss v-btn-large"
                            style=""
                            outlined
                            @click="showDialogForm = false"
                        >
                            Back
                        </v-btn>
                    </div>
                </v-card-actions>
            </v-card>


            <v-dialog
                v-model="showDialogFormRegister"
                transition="dialog-top-transition"
                persistent
                width="1000"
                :fullscreen="$vuetify.breakpoint.mobile"
            >
                <v-card>
                    <v-card-title class="text-h5 lighten-2 formDialog" style="border-bottom: solid 1px var(--color__cinza)">
                        Form
                    </v-card-title>

                    <v-card-text style="margin-top: 20px;">
                        <v-form
                            ref="formActionsForm"
                            v-model="validFormActionsForm"
                            lazy-validation
                        >
                            <v-row>
                                <v-col
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Order</label>
                                    <v-text-field
                                        v-model="order"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col  
                                    cols="12"
                                    lg="3"
                                    md="4"
                                    sm="12"
                                >
                                    <label>Answer Type</label>
                                    <v-combobox
                                        v-model="typeAnswerSelected"
                                        :items="listTypeAnswer"
                                        :rules=[validations.required]
                                        item-text="typeAnswerDescription"
                                        item-value="id"
                                        clearable
                                        outlined
                                        dense
                                        @change="checkTypeAnswer()"
                                    ></v-combobox>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="2"
                                    sm="12"
                                >
                                    <v-switch 
                                        v-model="mandatory"
                                        label="Mandatory ?"
                                        color="var(--color__main)"
                                        :value=1
                                    >
                                    </v-switch>
                                </v-col>
                                <v-col 
                                    cols="12"
                                    lg="3"
                                    md="2"
                                    sm="12"
                                >
                                    <v-switch 
                                        v-model="uploadPhoto"
                                        label="Upload Photo ?"
                                        color="var(--color__main)"
                                        :value=1
                                    >
                                    </v-switch>
                                </v-col>
                                <v-col
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <label>Question</label>
                                    <v-text-field
                                        v-model="question"
                                        :rules=[validations.required]
                                        single-line
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="typeAnswerEqualsOption"
                            >
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <ContentHeader :description="`Enter the ${typeAnswerDescription}`" />
                                </v-col>
                            </v-row>

                            <v-row
                                v-if="typeAnswerEqualsOption"
                            >
                                <v-col 
                                    cols="12"
                                    lg="12"
                                    md="12"
                                    sm="12"
                                >
                                    <v-row 
                                        v-for="(item, index) in listAnswer" :key="index" style="height: 60px;" 
                                    >
                                        <v-col 
                                            cols="9"
                                            lg="10"
                                            md="9"
                                            sm="9"
                                        >
                                            <v-text-field
                                                v-model="item.description" 
                                                :rules=[validations.required]
                                                single-line
                                                outlined
                                                dense
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col 
                                            cols="2"
                                            lg="2"
                                            md="2"
                                            sm="2"
                                            :class="item.showAddNewItem != true ? 'colButtonList' : 'colButtonList2'"
                                        >
                                            <v-tooltip top v-if="item.showAddNewItem == true" style="padding-bottom: 5px;">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn 
                                                        class="btnActions"
                                                        outlined
                                                        fab
                                                        x-small
                                                        v-on="on"
                                                        @click="addNewItemForm(item)" 
                                                        color="blue" 
                                                        small
                                                        :key="index"
                                                    >
                                                        <v-icon>mdi-plus</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Add New Item</span>
                                            </v-tooltip>
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn 
                                                        class="btnActions"
                                                        outlined
                                                        fab
                                                        x-small
                                                        v-on="on"
                                                        @click="deleteItemForm(index)" 
                                                        color="red" 
                                                        small
                                                        :disabled="listAnswer.length <= 1"
                                                    >
                                                        <v-icon>mdi-delete</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Delete Item</span>
                                            </v-tooltip>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>

                        </v-form>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <div style="width: 100%; text-align: center;">
                            <v-btn
                                class="mx-2 buttonCancelDialog v-btn-large"
                                style=""
                                outlined
                                @click="showDialogFormRegister = false"
                            >
                                Cancel
                            </v-btn>

                            <v-btn
                                class="mx-2 v-btn-large"
                                outlined
                                @click="saveActionsForm()"
                            >
                                Save
                            </v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-dialog>
    </v-container>
</template>

<script>
    import { required } from "@/utilities/Rules";
    import Helpers from '@/utilities/Helpers';
    import ActionButtons from "@/components/Layout/ActionButtons";
    import ContentHeader from "@/components/Layout/ContentHeader.vue";
    import ActionList from "@/components/Layout/ActionList";

    export default ({

        mixins: [Helpers],

        components: {
            ActionButtons,
            ContentHeader,
            ActionList
        },

        data: () => ({

            validForm: true,

            prefix: 'mdi mdi-',

            actionsRequest: {
                id: 0,
                status: 1,
                quickAccessMenu: 0,
                idPanel: 0,
                idStage: 0,
                panelSelected: null,
                stageSelected: null,
                icon: "",
                iconWithoutPrefix: "",
                labelName: "",
                startDate: 0,
                listIDProfileStartDateSelected: null,
                listIDProfileStartDate: '',
                endDate: 0,
                listIDProfileEndDateSelected: null,
                listIDProfileEndDate: '',
                sendDocument: 0,
                listIDProfileSendDocumentSelected: null,
                listIDProfileSendDocument: '',
                checkList: 0,
                listIDProfileCheckListSelected: null,
                listIDProfileCheckList: '',
                checkListTitle: '',
                listCheckList: [],
                notes: 0,
                listIDProfileNotes: '',
                subContractor: 0,
                listIDProfileSubcontractorSelected: null,
                listIDProfileSubcontractor: '',
                uploadPhoto: 0,
                listIDProfileUploadPhotoSelected: null,
                listIDProfileUploadPhoto: '',                
                listIDProfileDeadlineDateSelected: null,
                listIDProfileDeadlineDate: '',
                moveProject: 0,                
                listIDProfileMoveProjectSelected: null,
                listIDProfileMoveProject: '',
                idStageToMove: 0,
                stageToMoveSelected: null,
                inspection: 0,
                listIDProfileFormSelected: null,
                listIDProfileForm: '',
                formTitle: '',
                formDescription: '',
                listActionsForm: [],
                listIDActionsDependsOnSelected: null,
            },

            showDialogForm: false,
            headersActionsForm: [
                { text: "Order", value: "order", sortable: true, width: "5%" },
                { text: "Question", value: "question", sortable: true, width: "45%" },
                { text: "Type Answer", value: "typeAnswerDescription", sortable: true, width: "10%" },
                { text: "Mandatory", value: "mandatoryDescription", sortable: true, width: "10%" },
                { text: "Upload Photo", value: "uploadPhotoDescription", sortable: true, width: "10%" },
                { text: "Actions", value: "action", sortable: false, align: "center", width: "10%" }
            ],
            optionsActionsForm: { rowsPerPage: 10, page: 1 },

            //ACTIONS FORM
            validFormActionsForm: true,
            showDialogFormRegister: false,
            listTypeAnswer: [],
            order: 1,
            question: '',
            typeAnswerSelected: null,
            mandatory: 0,
            uploadPhoto: 0,
            listAnswer: [],
            itemActionsForm: null,

            listConstructionPanel: [],
            listConstructionStages: [],
            listConstructionStagesFiltered: [],

            listProfile: [],

            listActionsFiltered: [],

            showDialogCheckList: false,

            loading: false,
            
            validations: {
                required: required,
            }
        }),

        props: {
            id: {
                default: 0
            }
        },

        computed: {
            typeAnswerDescription() {
                if (this.typeAnswerSelected != null && this.typeAnswerSelected != undefined) {
                    return this.typeAnswerSelected.typeAnswerDescription;
                }
                else {
                    return ''
                }
            },

            typeAnswerEqualsOption() {
                return this.typeAnswerSelected != null && this.typeAnswerSelected != undefined && this.typeAnswerSelected.id == 2;
            },
        },

        methods: {
            
            async deleteItem(index) {
                this.actionsRequest.listCheckList.splice(index, 1);
                
                let lastIndex = this.actionsRequest.listCheckList.length - 1;

                this.actionsRequest.listCheckList[lastIndex].showAddNewItem = true;
            },

            async addNewItem(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                this.actionsRequest.listCheckList.push({
                    description: null,
                    showAddNewItem: true
                })
            },

            async openDialogCheckList() {
                if (this.actionsRequest.listCheckList.length <= 0) {
                    this.addNewItem();
                }
                else {
                    let lastIndex = this.actionsRequest.listCheckList.length - 1;
                    if (this.actionsRequest.listCheckList[lastIndex].description != '') {
                        this.addNewItem();
                    }
                }

                this.showDialogCheckList = true;
            },

            filterStages() {

                if (this.actionsRequest.panelSelected != null && this.actionsRequest.panelSelected != undefined) {
                    this.actionsRequest.stageSelected = null;
                    this.listConstructionStagesFiltered = this.listConstructionStages.filter(stage => stage.idPanel == this.actionsRequest.panelSelected.id);
                }
            },

            setMdiIcon() {
                this.actionsRequest.icon = this.prefix + this.actionsRequest.iconWithoutPrefix;
            },

            async getLists() {

                this.listConstructionPanel = await this.$store.dispatch("serviceModule/ListConstructionPanel");
                this.listConstructionStages = await this.$store.dispatch("serviceModule/ListConstructionStages");
                this.listProfile = await this.$store.dispatch("profileModule/List");
                this.listTypeAnswer = await this.$store.dispatch("actionsModule/ListTypeAnswer");
            },
            
            async getRegister() {

                if (this.id !== 0) {

                    let response = await this.$store.dispatch("actionsModule/GetById", this.id);

                    if (response.success) {
                        this.actionsRequest = response.result;
                        this.actionsRequest.iconWithoutPrefix = this.actionsRequest.icon.replace("mdi mdi-", "");

                        console.log('this.actionsRequest', this.actionsRequest);

                        this.actionsRequest.panelSelected = {
                            id: this.actionsRequest.idPanel,
                            panelDescription: this.actionsRequest.panelDescription
                        }

                        await this.filterStages();

                        this.actionsRequest.stageSelected = {
                            id: this.actionsRequest.idStage,
                            stageDescription: this.actionsRequest.stageDescription
                        }

                        if (this.actionsRequest.moveProject == 1) {
                            this.actionsRequest.stageToMoveSelected = {
                                id: this.actionsRequest.idStageToMove,
                                stageDescription: this.actionsRequest.stageToMoveDescription
                            }
                        }

                        const listActions = await this.$store.dispatch("actionsModule/List");
                        this.listActionsFiltered = listActions.filter(act => act.idPanel <= this.actionsRequest.idPanel && act.idStage < this.actionsRequest.idStage);
                    }
                }
            },

            async cancel() {
                this.$router.push({ path: "/actions/actionsList" });
            },
            

            //ACTIONS FORM
            async checkTypeAnswer() {
                if (this.typeAnswerEqualsOption) {
                    if (this.listAnswer == null || this.listAnswer == undefined || this.listAnswer.length <= 0) {
                        this.addNewItemForm();
                    }
                }
            },

            async clearFieldsActionsForm() {

                this.order = 1;

                if (this.actionsRequest.listActionsForm != null && this.actionsRequest.listActionsForm != undefined) {
                    this.order = this.actionsRequest.listActionsForm.length + 1;
                }

                this.question = '';
                this.typeAnswerSelected = null;
                this.mandatory = 0;
                this.uploadPhoto = 0;
                this.itemActionsForm = null;
                this.listAnswer = [];
            },

            async showDialogActionsFormAndClearFields()  {

                this.clearFieldsActionsForm();
                this.showDialogFormRegister = true;
            },

            async showDialogActionsFormAndFillFields(id) {
                this.clearFieldsActionsForm();
                this.itemActionsForm = this.actionsRequest.listActionsForm.filter(con => con.id == id)[0];

                if (this.itemActionsForm != null && this.itemActionsForm != undefined) {
                    
                    this.order = this.itemActionsForm.order;
                    this.question = this.itemActionsForm.question;
                    this.typeAnswerSelected = {
                        id: this.itemActionsForm.typeAnswer,
                        typeAnswerDescription: this.itemActionsForm.typeAnswerDescription
                    };
                    this.mandatory = this.itemActionsForm.mandatory;
                    this.uploadPhoto = this.itemActionsForm.uploadPhoto;
                    this.listAnswer = this.itemActionsForm.listAnswer;

                    this.showDialogFormRegister = true;
                }
            },

            async saveActionsForm() {

                await this.$refs.formActionsForm.validate();

                if (this.validFormActionsForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the contact form. Please review!");
                    return false;
                }
                else {

                    var existsActionsForm = this.actionsRequest.listActionsForm.filter(con => con.question === this.question);

                    if (existsActionsForm.length > 0 && this.itemActionsForm == null) {
                        this.showToast("error", "Warning!", "Question already registered!");
                    }
                    else {                        
                        if (this.itemActionsForm == null) {
                            this.actionsRequest.listActionsForm.push({
                                id: Math.random(),
                                order: this.order,
                                question: this.question,
                                typeAnswer: this.typeAnswerSelected.id,
                                typeAnswerDescription: this.typeAnswerSelected.typeAnswerDescription,
                                mandatory: this.mandatory,
                                mandatoryDescription: this.mandatory == 1 ? 'YES' : 'NO',
                                uploadPhoto: this.uploadPhoto,
                                uploadPhotoDescription: this.uploadPhoto == 1 ? 'YES' : 'NO',
                                listAnswer: this.listAnswer,
                                newRegister: true
                            })
                        }
                        else {
                            this.itemActionsForm.order = this.order;
                            this.itemActionsForm.question = this.question;
                            this.itemActionsForm.typeAnswer = this.typeAnswerSelected.id;
                            this.itemActionsForm.typeAnswerDescription = this.typeAnswerSelected.typeAnswerDescription;
                            this.itemActionsForm.mandatory = this.mandatory == null ? 0 : this.mandatory;
                            this.itemActionsForm.mandatoryDescription = this.mandatory == 1 ? 'YES' : 'NO';
                            this.itemActionsForm.uploadPhoto = this.uploadPhoto == null ? 0 : this.uploadPhoto;
                            this.itemActionsForm.uploadPhotoDescription = this.uploadPhoto == 1 ? 'YES' : 'NO';
                            this.itemActionsForm.listAnswer = this.listAnswer;
                        }

                        this.showDialogFormRegister = false;
                    }
                }
            },

            confirmActionsFormDelete(id) {

                let index = 0
                this.actionsRequest.listActionsForm.forEach(item => {
                    if (item.id == id) {

                        this.actionsRequest.listActionsForm.splice(index, 1);
                    }
                    index++;
                });
            },
            
            async deleteItemForm(index) {
                this.listAnswer.splice(index, 1);
                
                let lastIndex = this.listAnswer.length - 1;

                this.listAnswer[lastIndex].showAddNewItem = true;
            },

            async addNewItemForm(itemAtual) {

                if (itemAtual !== null && itemAtual !== undefined) {
                    itemAtual.showAddNewItem = false;
                }

                this.listAnswer.push({
                    description: null,
                    showAddNewItem: true
                })
            },

            async save() {

                await this.$refs.form.validate();

                if (this.validForm === false) {
                    this.showToast("error", "Warning!", "There are inconsistencies in the form. Please review!");
                    return false;
                }
                else {
                    this.loading = true;
                    this.showLoading();

                    this.actionsRequest.id = this.id;

                    if (this.actionsRequest.panelSelected != null && this.actionsRequest.panelSelected != undefined && this.actionsRequest.panelSelected.id != 0) {
                        this.actionsRequest.idPanel = this.actionsRequest.panelSelected.id;
                    }

                    if (this.actionsRequest.stageSelected != null && this.actionsRequest.stageSelected != undefined && this.actionsRequest.stageSelected.id != 0) {
                        this.actionsRequest.idStage = this.actionsRequest.stageSelected.id;
                    }

                    if (this.actionsRequest.status === null) {
                        this.actionsRequest.status = 0;
                    }

                    if (this.actionsRequest.quickAccessMenu === null) {
                        this.actionsRequest.quickAccessMenu = 0;
                    }

                    if (this.actionsRequest.startDate === null) { this.actionsRequest.startDate = 0; }
                    if (this.actionsRequest.endDate === null) { this.actionsRequest.endDate = 0; }
                    if (this.actionsRequest.sendDocument === null) { this.actionsRequest.sendDocument = 0; }
                    if (this.actionsRequest.checkList === null) { this.actionsRequest.checkList = 0; }
                    if (this.actionsRequest.notes === null) { this.actionsRequest.notes = 0; }
                    if (this.actionsRequest.subContractor === null) { this.actionsRequest.subContractor = 0; }
                    if (this.actionsRequest.uploadPhoto === null) { this.actionsRequest.uploadPhoto = 0; }
                    if (this.actionsRequest.moveProject === null) { this.actionsRequest.moveProject = 0; }
                    if (this.actionsRequest.deadlineDate === null) { this.actionsRequest.deadlineDate = 0; }
                    if (this.actionsRequest.inspection === null) { this.actionsRequest.inspection = 0; }
                    if (this.actionsRequest.form === null) { this.actionsRequest.form = 0; }


                    if (this.actionsRequest.startDate == 0) { 
                        this.actionsRequest.listIDProfileStartDate = ''; 
                        this.actionsRequest.listIDProfileStartDateSelected = null; 
                    }

                    if (this.actionsRequest.endDate == 0) { 
                        this.actionsRequest.listIDProfileEndDate = ''; 
                        this.actionsRequest.listIDProfileEndDateSelected = null; 
                    }

                    if (this.actionsRequest.sendDocument == 0) { 
                        this.actionsRequest.listIDProfileSendDocument = ''; 
                        this.actionsRequest.listIDProfileSendDocumentSelected = null; 
                    }

                    if (this.actionsRequest.checkList == 0) { 
                        this.actionsRequest.listIDProfileCheckList = ''; 
                        this.actionsRequest.listIDProfileCheckListSelected = null; 
                    }

                    if (this.actionsRequest.notes == 0) { 
                        this.actionsRequest.listIDProfileNotes = '';
                        this.actionsRequest.listIDProfileNotesSelected = null;
                    }

                    if (this.actionsRequest.subContractor == 0) { 
                        this.actionsRequest.listIDProfileSubcontractor = ''; 
                        this.actionsRequest.listIDProfileSubcontractorSelected = null; 
                    }
                    
                    if (this.actionsRequest.uploadPhoto == 0) { 
                        this.actionsRequest.listIDProfileUploadPhoto = ''; 
                        this.actionsRequest.listIDProfileUploadPhotoSelected = null; 
                    }
                    
                    if (this.actionsRequest.deadlineDate == 0) { 
                        this.actionsRequest.listIDProfileDeadlineDate = ''; 
                        this.actionsRequest.listIDProfileDeadlineDateSelected = null; 
                    }
                    
                    if (this.actionsRequest.moveProject == 0) { 
                        this.actionsRequest.listIDProfileMoveProject = ''; 
                        this.actionsRequest.listIDProfileMoveProjectSelected = null; 
                    }
                    
                    if (this.actionsRequest.form == 0) { 
                        this.actionsRequest.listIDProfileForm = ''; 
                        this.actionsRequest.listIDProfileFormSelected = null; 
                    }

                    if (this.actionsRequest.listIDProfileStartDateSelected != null && this.actionsRequest.listIDProfileStartDateSelected != undefined) {
                        this.actionsRequest.listIDProfileStartDate = '';

                        this.actionsRequest.listIDProfileStartDateSelected.forEach(item => {
                            this.actionsRequest.listIDProfileStartDate += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileEndDateSelected != null && this.actionsRequest.listIDProfileEndDateSelected != undefined) {
                        this.actionsRequest.listIDProfileEndDate = '';

                        this.actionsRequest.listIDProfileEndDateSelected.forEach(item => {
                            this.actionsRequest.listIDProfileEndDate += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileSendDocumentSelected != null && this.actionsRequest.listIDProfileSendDocumentSelected != undefined) {
                        this.actionsRequest.listIDProfileSendDocument = '';

                        this.actionsRequest.listIDProfileSendDocumentSelected.forEach(item => {
                            this.actionsRequest.listIDProfileSendDocument += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileCheckListSelected != null && this.actionsRequest.listIDProfileCheckListSelected != undefined) {
                        this.actionsRequest.listIDProfileCheckList = '';

                        this.actionsRequest.listIDProfileCheckListSelected.forEach(item => {
                            this.actionsRequest.listIDProfileCheckList += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileNotesSelected != null && this.actionsRequest.listIDProfileNotesSelected != undefined) {
                        this.actionsRequest.listIDProfileNotes = '';

                        this.actionsRequest.listIDProfileNotesSelected.forEach(item => {
                            this.actionsRequest.listIDProfileNotes += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileSubcontractorSelected != null && this.actionsRequest.listIDProfileSubcontractorSelected != undefined) {
                        this.actionsRequest.listIDProfileSubcontractor = '';

                        this.actionsRequest.listIDProfileSubcontractorSelected.forEach(item => {
                            this.actionsRequest.listIDProfileSubcontractor += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileUploadPhotoSelected != null && this.actionsRequest.listIDProfileUploadPhotoSelected != undefined) {
                        this.actionsRequest.listIDProfileUploadPhoto = '';

                        this.actionsRequest.listIDProfileUploadPhotoSelected.forEach(item => {
                            this.actionsRequest.listIDProfileUploadPhoto += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileDeadlineDateSelected != null && this.actionsRequest.listIDProfileDeadlineDateSelected != undefined) {
                        this.actionsRequest.listIDProfileDeadlineDate = '';

                        this.actionsRequest.listIDProfileDeadlineDateSelected.forEach(item => {
                            this.actionsRequest.listIDProfileDeadlineDate += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileMoveProjectSelected != null && this.actionsRequest.listIDProfileMoveProjectSelected != undefined) {
                        this.actionsRequest.listIDProfileMoveProject = '';

                        this.actionsRequest.listIDProfileMoveProjectSelected.forEach(item => {
                            this.actionsRequest.listIDProfileMoveProject += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.listIDProfileFormSelected != null && this.actionsRequest.listIDProfileFormSelected != undefined) {
                        this.actionsRequest.listIDProfileForm = '';

                        this.actionsRequest.listIDProfileFormSelected.forEach(item => {
                            this.actionsRequest.listIDProfileForm += `${item.id};`
                        });
                    }

                    if (this.actionsRequest.stageToMoveSelected != null && this.actionsRequest.stageToMoveSelected != undefined) {
                        this.actionsRequest.idStageToMove = this.actionsRequest.stageToMoveSelected.id;
                    }
                    
                    let listNewActionsForm = this.actionsRequest.listActionsForm.filter (act => act.newRegister === true);
                    if (listNewActionsForm != null && listNewActionsForm != undefined) {
                        listNewActionsForm.forEach (itemActionsForm => {
                            itemActionsForm.id = 0
                        })
                    }

                    if (this.actionsRequest.listIDActionsDependsOnSelected != null && this.actionsRequest.listIDActionsDependsOnSelected != undefined) {
                        this.actionsRequest.listIDActionsDependsOn = '';

                        this.actionsRequest.listIDActionsDependsOnSelected.forEach(item => {
                            this.actionsRequest.listIDActionsDependsOn += `${item.id};`
                        });
                    }

                    const result = await this.$store.dispatch("actionsModule/CreateUpdate", this.actionsRequest);

                    if (result.success === true) {
                        this.showToast("success", "Success!", result.message);
                        this.cancel();
                    }
                    else {
                        this.showToast("error", "Warning!", result.message);
                    }

                    this.loading = false;
                    this.hideLoading();                        
                }
            }
        },

        async created() {
            this.showLoading();

            await this.getLists();
            await this.getRegister();

            this.hideLoading();
        }
    })
</script>

<style scoped>

    .colButtonList {
        padding-top: 0px;    
    }

    .colButtonList2 {
        margin-top: inherit;
    }
</style>